import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import http from "../../Service/http.service";
import { Validation } from "../../Utils/Utils";
import { useModalContext } from "../../Context/SnackbarContext";

const Register = () => {
  const navigation = useNavigate();
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState();
  const { setShowAuthModal, setShowMessage, setSeverity } = useModalContext();
  const [disableButton, setDisableButton] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegisterData({
      ...registerData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage();
    //// api call here
    let checkValidation = Validation(registerData);
    
    if (Object.keys(checkValidation).length === 0) {
      setDisableButton(true);
      try {
        const response = await http.post("auth/register", { ...registerData });
        if (response?.data) {
          setShowMessage(response?.data?.message);
          setSeverity("success");
          setShowAuthModal(true);
          navigation("/login");
        } else {
          console.log("error:", response.data.message);
        }
      } catch (error) {
        setShowMessage(error?.response?.data?.message);
        setSeverity("success");
        setShowAuthModal(true);
      }
      setDisableButton(false);
    } else {
      setErrorMessage(checkValidation);
    }
  };

  return (
    <Box className="formModule">
      <Container component="main" maxWidth="lg">
        <Grid container justifyContent={"center"}>
          <Grid item xl={7} md={8} xs={12}>
            <Box className="formModule__body">
              <Box className="formModule__content">
                <Typography variant="h4" className="formModule__title">
                  Welcome to our affiliate Program 👋
                </Typography>
                <p>
                  Sign up to become our affiliate and earn rewards.After sign
                  up, you will get access to your custom referral link and your
                  own dashboard
                </p>
              </Box>
              <Box className="formModule__card">
                <form onSubmit={handleSubmit}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className="formModule__card-title"
                  >
                    Become our affiliate. Sign up Here!
                  </Typography>
                  <Box className="formField">
                    <label>Name</label>
                    <Box>
                    <TextField
                      type="text"
                      name="name"
                      value={registerData?.name}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                    <Typography variant="p">{errorMessage?.name}</Typography>
                    </Box>
                  </Box>
                  <Box className="formField">
                    <label>Email</label>
                    <Box>
                    <TextField
                      type="email"
                      name="email"
                      value={registerData?.email}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                    <Typography variant="p" className="errorText">
                      {errorMessage?.email}
                    </Typography>
                    </Box>
                  </Box>
                  <Box className="formField">
                    <label>Password</label>
                    <TextField
                      type="password"
                      name="password"
                      value={registerData?.password}
                      onChange={(e) => handleChange(e)}
                      margin="normal"
                      fullWidth
                    />
                    <Typography variant="p" className="errorText">
                      {errorMessage?.password}
                    </Typography>
                  </Box>
                  <Box className="formModule__button">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disableButton}
                      fullWidth
                    >
                      Sign up
                    </Button>
                  </Box>
                </form>
                <p className="formModule__link">
                  Already Account?
                  <div
                    onClick={() => {
                      navigation("/login");
                    }}
                  >
                    Sign In
                  </div>
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Register;
