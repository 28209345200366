import React from "react";
import { Layout } from "react-admin";
import Header from "./Header/Header";
import CustomSidebar from "./Sidebar/Sidebar";

const CustomLayout = (props) => (
  <Layout {...props} appBar={Header} sidebar={CustomSidebar} />
);



export default CustomLayout;
