export const checkIsEmail = (email) => {
    const emailRegexp = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    return emailRegexp.test(email);
};

export const Validation = (data) => {
    let errorObject = {}

    if ('name' in data && data?.name?.length === 0) {
        Object.assign(errorObject, { name: "Name is required" });
    }
    if ('email' in data && data?.email?.length === 0) {
        Object.assign(errorObject, { email: "Email is required" });
    }
    if ('email' in data && data?.email?.length !== 0 && !checkIsEmail(data?.email)) {
        Object.assign(errorObject, { email: "Incorrect email address" })
    }
    if ('password' in data && data?.password?.length === 0) {
        Object.assign(errorObject, { password: "Password is required" })
    }

    return errorObject;
}
