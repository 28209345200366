import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box,  Button,  IconButton, TextField, Tooltip, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import './dashboard.scss'

const Dashboard = () => {
  const arrayString = localStorage.getItem("affiliate-user");
  let userExist = {};
  userExist =
    arrayString && arrayString !== "undefined" ? JSON.parse(arrayString) : null;

  const [isLink, setIsLink] = useState("");
  const [copyState, setCopyState] = useState(false);
  const testRef = useRef(null);

  const navigator = useNavigate();

  useEffect(() => {
    if (!userExist) {
      navigator("/login");
    } else {
      setIsLink(`${process.env.REACT_APP_FRONTEND_URL}?ref=${userExist?.affilateID}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const handleCopyLink = async(text) => {
   
    // Create a temporary input element
    const tempInput = document.createElement('input');
    tempInput.value = text;
    document.body.appendChild(tempInput);
    // Select the text inside the input element
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // For mobile devices
    // Execute the copy command
    document.execCommand('copy');
    // Remove the temporary input element
    setCopyState(true)

  };

  return (
    <>
      <Box p={"30px"} className="pageTemplate">
        <Box className="referralBlock">
          <Typography variant="h4" className="referralBlock__title"> <span>Referral Link & Assets</span></Typography>

          <Box className="referralBlock__content">
            <Typography variant="h4" className="referralBlock__subtitle">
              Referral Link
            </Typography>
            <p>
              This is your personal unique link to promote this program and get
              commission.
            </p>
            <Box className="referralBlock__card">
              <Typography variant="h4" className="referralBlock__card-title">
                Copy Link
              </Typography>
              <div className="referralBlock__card-field">
                <TextField
                ref={testRef}
                  id="myInput"
                  label="Your Shareable Link"
                  variant="outlined"
                  value={isLink}
                  fullWidth
                  disabled
                />
                <Tooltip title={copyState ? "Copied" : "Copy"}>
                  <Button
                    onClick={()=>handleCopyLink(isLink)}
                    >
                  <IconButton
                    variant="contained"
                  >
                    <ContentCopyIcon />
                  </IconButton>
                  </Button>
                </Tooltip>
              </div>
            </Box>
          </Box>
        </Box>

      </Box >
    </>
  );
};

export default Dashboard;
