import React from "react";
import { AppBar, UserMenu,  useUserMenu } from "react-admin";
import { Box, ListItemIcon, MenuItem, ListItemText } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from "react-router-dom";
import "./header.scss"

const SettingsMenuItem = React.forwardRef((props, ref) => {
  const { onClose } = useUserMenu();
  const navigator = useNavigate();
  const handleLogout =()=>{
    localStorage.removeItem("affiliate-user");
    navigator("/login");
  }
  return (
    <>
      <MenuItem onClick={onClose} ref={ref} {...props}>
        <ListItemIcon>
          <SettingsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Customize</ListItemText>
      </MenuItem>
      <MenuItem ref={ref} {...props} onClick={handleLogout}>
        <ListItemIcon >
          <ExitToAppIcon fontSize="small"  />
        </ListItemIcon>
        <ListItemText>Logout</ListItemText>
      </MenuItem>
    </>
  );
});

const Header = () => {
  return (
    <AppBar
      // color="primary"
      userMenu={
        <UserMenu>
          <SettingsMenuItem />
        </UserMenu>
      }
    >
      <Box flex="1" />
    </AppBar>
  );
};

export default Header;
