import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Admin, Resource } from "react-admin";
import LoginPage from "./Pages/Login/login";
import RegisterPage from "./Pages/Register/Register";
import CustomLayout from "./Components/Layout/Layout";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Post from "./Pages/Post";
import "./App.css";
import VerifyEmail from "./Pages/VerifyEmail";
import { ModalContext } from "./Context/SnackbarContext";

import SnackBar from "./Components/Snackbar/Snackbar";
import VerifyEmailExist from "./Pages/VerifyEmailExist";


const publicPages = ["/login", "/register", "/verifyEmail","/verifyEmailExist"];

function App() {
  const navigator = useNavigate();
  let { pathname } = useLocation();
  const arrayString = localStorage.getItem("affiliate-user");
  const userExist = arrayString ? JSON.parse(arrayString) : null;

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [severity, setSeverity] = useState();

  const checkAuth = () => {
    if (!userExist) {
      navigator("/login");
    }
  };
  useEffect(() => {
    const isPublicPage = publicPages.includes(pathname);
    if (!isPublicPage) {
      checkAuth();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalContext.Provider
        value={{
          showAuthModal,
          setShowAuthModal,
          showMessage,
          setShowMessage,
          severity,
          setSeverity,
        }}
      >
        {userExist ? ( // Conditional rendering based on authentication state
          <Admin layout={CustomLayout} dashboard={Dashboard}>
            <Resource />
            <Resource name="posts" list={Post} />
          </Admin>
        ) : (
          <Routes>
            {/* Public pages */}
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/verifyEmail" element={<VerifyEmail />} />
            <Route path="/verifyEmailExist" element={<VerifyEmailExist />} />
          </Routes>
        )}

        <SnackBar
          show={showAuthModal}
          setShowAuthModal={setShowAuthModal}
          showMessage={showMessage}
          severity={severity}
        />
      </ModalContext.Provider>
    </>
  );
}

export default App;
