import { Alert, Snackbar } from "@mui/material";

const SnackBar = ({show , setShowAuthModal, showMessage , severity}) => {

  return (
    <Snackbar
      open={show}
      autoHideDuration={2000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      onClose={() => {
        setShowAuthModal(false)
      }}
    >
      <Alert
        severity={severity}
        sx={{ width: "100%" }}
      >
        {showMessage}
      </Alert>
    </Snackbar>
  );
};

export default SnackBar;
