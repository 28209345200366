import React from 'react'

const Post = () => {
  return (
    <div>
      this the post component
    </div>
  )
}

export default Post
