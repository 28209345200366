import React from "react";
import { Menu, Sidebar } from "react-admin";
import DashboardIcon from "@mui/icons-material/Dashboard";

const CustomSidebar = () => {
  return (
    <Sidebar sx={{
      background:"whitesmoke"
    }}>
      <Menu>
        <Menu.Item
          to="/"
          primaryText="Dashboard"
          leftIcon={<DashboardIcon />}
        />
        {/* <Menu.Item to="/posts" primaryText="Posts" leftIcon={<BookIcon />} />
        <Menu.Item
          to="/comments"
          primaryText="Comments"
          leftIcon={<ChatBubbleIcon />}
        />
        <Menu.Item to="/users" primaryText="Users" leftIcon={<PeopleIcon />} />
        <Menu.Item
          to="/custom-route"
          primaryText="Miscellaneous"
          leftIcon={<LabelIcon />}
        /> */}
      </Menu>
    </Sidebar>
  );
};

export default CustomSidebar;
