import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import api from "../../Service/http.service";
import { Validation } from "../../Utils/Utils";
import { useModalContext } from "../../Context/SnackbarContext";
import "./login.scss";

const Login = () => {
  const navigation = useNavigate();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const { setShowAuthModal, setShowMessage, setSeverity } = useModalContext();
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage();
  
    let checkValidation = Validation(form);

    if (Object.keys(checkValidation).length === 0) {
      setDisableButton(true);
      //// api integration here
      try {
        const response = await api.post("auth/login", { ...form });
        if (response?.data) {
          localStorage.setItem(
            "affiliate-user",
            JSON.stringify(response?.data?.user)
          );
          setShowMessage(response?.data?.message);
          setSeverity("success");
          setShowAuthModal(true);
          navigation("/");
        } else {
          console.log("response", response);
        }
      } catch (e) {
        setShowMessage(e?.response?.data?.message);
        setSeverity("error");
        setShowAuthModal(true);
      }
      setDisableButton(false);
    } else {
      setErrorMessage(checkValidation);
    }
  };

  return (
    <Box className="formModule">
      <Container component="main" maxWidth="lg">
        <Grid container justifyContent={"center"}>
          {/* <Grid item xs={12}>
            <Box className="formModule__head">
              <Link>OrTiger PTE LTD</Link>
              <Button>Login</Button>
            </Box>
          </Grid> */}
          <Grid item xl={7} md={8} xs={12}>
            <Box className="formModule__body">
              <Box className="formModule__content">
                <Typography variant="h4" className="formModule__title">
                  Welcome to our affiliate Program 👋
                </Typography>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s.
                </p>
              </Box>
              <Box className="formModule__card">
                <form onSubmit={handleSubmit}>
                  <Typography
                    variant="h4"
                    gutterBottom
                    className="formModule__card-title"
                  >
                    Become our affiliate. Sign In Here!
                  </Typography>
                  <Box className="formField">
                    <label>Username</label>
                    <Box>
                      <TextField
                        type="text"
                        name="email"
                        value={form?.email}
                        onChange={(e) => {
                          setForm({
                            ...form,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        margin="normal"
                        fullWidth
                      />
                      <Typography variant="p" className="errorText">
                        {errorMessage?.email}
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="formField">
                    <label>Password</label>
                    <Box>
                    <TextField
                      type="password"
                      name="password"
                      value={form?.password}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      margin="normal"
                      fullWidth
                    />
                    <Typography variant="p" className="errorText">
                      {errorMessage?.password}
                    </Typography>
                    </Box>
                  </Box>
                  <Box className="formModule__button">
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={disableButton}
                    >
                      Log In
                    </Button>
                  </Box>
                </form>
                <p className="formModule__link">
                  Don't have an account?
                  <div
                    onClick={() => {
                      navigation("/register");
                    }}
                  >
                    Sign Up
                  </div>
                </p>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Login;
