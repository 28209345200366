import React from "react";
import { Box, Container, Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Login/login.scss";

const VerifyEmail = () => {
  const navigator = useNavigate();

  return (
    <Box className="formModule">
      <Container component="main" maxWidth="lg">
        <Grid container justifyContent={"center"}>
          <Grid item xl={7} md={8} xs={12}>
            <Box className="formModule__body">
              <Box className="formModule__content">
                <Typography variant="h4" className="formModule__title">
                  Welcome to our affiliate Program 👋
                </Typography>
              </Box>
              <Box className="formModule__card">
                <Typography
                  variant="h4"
                  gutterBottom
                  className="formModule__card-title mb-50"
                >
                  Your Email is verify Successfully so please login with the
                  email and password{" "}
                </Typography>
                <Box className="formModule__button">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigator("/login");
                  }}
                >
                  Log In
                </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default VerifyEmail;
